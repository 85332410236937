<template>
  <b-modal

    v-model="ownControl"

    title-class="h3 text-white font-weight-bolder"
    modal
    modal-class="modal-primary"
    size="lg"
    :title="title"
    @hidden="closeModal"
  >

    <template #default>
      <validation-observer ref="form">
        <b-row />
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors,valid }"
              name="Bank_name"
              rules="required"
            >

              <b-form-group
                id="fieldset-1"
                label="Bank Name"
                label-for="input-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="input-1"
                  v-model="bankAccountData.bank_name"
                  :state="errors[0] ? false : valid ? true : null"
                  trim
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors,valid }"
              name="Number_Account"
              rules="required"
            >

              <b-form-group
                id="fieldset-1"
                label="Number Account"
                label-for="input-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="input-1"
                  v-model="bankAccountData.number_account"
                  :state="errors[0] ? false : valid ? true : null"
                  trim
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="currency"
              rules="required"
            >

              <b-form-group
                id="fieldset-1"
                label="Currency"
                label-for="input-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />

                <v-select
                  v-if=" finishedLoading"

                  v-model="bankAccountData.currency"
                  :class="{ 'border-error rounded': errors[0] }"
                  label="name"
                  :reduce="val => val.currency"
                  :options="optionsCurrency"
                  :disabled="edit"
                  @input="balanceprepends"
                >
                  <template #no-options>
                    You already registered the maximum number of bank accounts
                  </template>
                </v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Balance"
              rules="required|money-1"
            >

              <b-form-group
                id="fieldset-1"
                label="Balance"
                label-for="input-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-input-group
                  v-if="finishedLoading"
                  :prepend="balancePrepend"
                >

                  <money

                    v-model="bankAccountData.balance"
                    v-bind="vMoney"
                    class="form-control"
                    :class="{ 'border-error': errors[0] && validator }"
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors,valid }"
              name="User"
              rules="required"
            >

              <b-form-group
                id="fieldset-1"
                label="User"
                label-for="input-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="input-1"
                  v-model="bankAccountData.user_name"
                  :state="errors[0] ? false : valid ? true : null"
                  trim
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors,valid }"
              name="Password"
              rules="required"
            >

              <b-form-group
                id="fieldset-1"
                label="Password"
                label-for="input-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="input-1"
                  v-model="bankAccountData.password"
                  :state="errors[0] ? false : valid ? true : null"
                  trim
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

    </template>

    <template #modal-footer>
      <b-button
        v-if="edit===false"
        variant="primary"
        @click="createBankAccount"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        SAVE
      </b-button>

      <b-button
        v-if="edit===true"
        variant="primary"
        @click="updatedBankAccount"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        UPDATE
      </b-button>

    </template>

  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

import CompaniesService from '@/views/management/views/companies-old/companies.service'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    edit: {
      type: Boolean,
      default: null,
    },
    items: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      vMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      validator: false,
      validateMoney: false,
      optionsCurrency: [],
      finishedLoading: false,
      ownControl: false,
      balancePrepend: '',
      spinnerOn: false,

      bankAccountData: {
        bank_name: null,
        currency: null,
        user_name: null,
        password: null,

        balance: 0,
        number_account: null,

      },
      bankCounter: [],
      title: '',
      companyInformation: [],
      bankDataEdit: {},

    }
  },

  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),

  },

  async created() {
    this.ownControl = true
    this.putTitle()
    await Promise.all([
      this.getCountBankAccount(),
      this.getCompany(),
    ])

    this.typeOfAccount()
    await this.editModeData()
    this.finishedLoading = true
  },
  methods: {
    async editModeData() {
      if (this.items) {
        await this.getBankAccount()

        this.bankAccountData.bank_name = this.bankDataEdit.bank_name
        this.bankAccountData.currency = this.bankDataEdit.currency === 1 ? this.bankDataEdit.currency = '.S/' : this.bankDataEdit.currency = '$'
        this.bankAccountData.user_name = this.bankDataEdit.user
        this.bankAccountData.password = this.bankDataEdit.password

        this.bankAccountData.balance = this.bankDataEdit.balance
        this.bankAccountData.number_account = this.bankDataEdit.number_account
        this.balancePrepend = this.bankAccountData.currency
      }
    },
    typeOfAccount() {
      if (this.bankCounter.length > 0) {
        if (this.bankCounter.length === 1 && this.companyInformation !== 'US') {
          for (let i = 0; i < this.bankCounter.length; i++) {
            if (this.companyInformation === 'PE' && this.bankCounter[i].currency_number === 1) {
              this.optionsCurrency = [{ currency: '2', name: '$' }]
            } else {
              this.optionsCurrency = [{ currency: '1', name: 'S./' }]
            }
          }
        } else {
          this.optionsCurrency = []
        }
      } else if (this.companyInformation === 'US') {
        this.optionsCurrency = [{ currency: '2', name: '$' }]
      } else {
        this.optionsCurrency = [{ currency: '1', name: 'S./' }, { currency: '2', name: '$' }]
      }
    },

    putTitle() {
      if (this.edit === true) {
        this.title = 'UPDATE BANK ACCOUNT'
      }
      if (this.edit === false) {
        this.title = 'CREATE BANK ACCOUNT'
      }
    },
    closeModal() {
      this.$emit('close')
    },

    balanceprepends() {
      if (this.bankAccountData.currency === '1') {
        this.balancePrepend = '.S/'
      } else {
        this.balancePrepend = '$'
      }
    },
    // eslint-disable-next-line consistent-return
    async createBankAccount() {
      this.validator = true
      try {
        const result = await this.$refs.form.validate()

        if (result) {
          this.spinnerOn = true
          const params = {

            bank_name: this.bankAccountData.bank_name,
            currency: this.bankAccountData.currency,
            user_name: this.bankAccountData.user_name,
            password: this.bankAccountData.password,
            idcompany: Number(this.$route.params.id),
            user_id: this.currentUser.user_id,

            balance: this.bankAccountData.balance,
            number_account: this.bankAccountData.number_account,

          }

          const data = await CompaniesService.saveBankAccounts(params)
          if (data.status === 200) {
            this.spinnerOn = false
            this.$emit('refresh')
            this.showSuccessSwal()
            this.closeModal()
          }
        }
      } catch (e) {
        this.spinnerOn = false
        this.showErrorSwal(e)
        return []
      }
    },
    async updatedBankAccount() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()

        if (result) {
          const params = {

            bank_name: this.bankAccountData.bank_name,
            currency: this.bankAccountData.currency === '$' ? this.bankAccountData.currency = 2 : this.bankAccountData.currency = 1,
            user_name: this.bankAccountData.user_name,
            password: this.bankAccountData.password,
            idcompany: Number(this.$route.params.id),
            user_id: this.currentUser.user_id,
            idbankaccount: this.items.id,
            balance: this.bankAccountData.balance,
            number_account: this.bankAccountData.number_account,

          }

          const data = await CompaniesService.updateBankAccounts(params)
          if (data.status === 200) {
            this.$emit('refresh')
            this.showSuccessSwal()
            this.closeModal()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async getCountBankAccount() {
      try {
        const params = {
          idcompany: Number(this.$route.params.id),
        }
        const data = await CompaniesService.getBankAccountCounter(params)

        this.bankCounter = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.bankCounter
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async getCompany() {
      try {
        const params = {
          company_id: (this.$route.params.id).toString(),

        }

        const data = await CompaniesService.getCompanyCountryById(params)
        this.companyInformation = data.data[0].country

        return this.companyInformation
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    // eslint-disable-next-line consistent-return
    async getBankAccount() {
      try {
        const params = {
          idbankaccount: this.items.id,

        }

        const data = await CompaniesService.getBankAccountById(params)
        this.bankDataEdit = data.data[0]
        return this.bankDataEdit
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },
}
</script>

<style>
.border-error{
  border: 1px solid #fc424a !important;
}
</style>
